import Navigation from './Navigation';

function Header() {
  return (
    <div>
      <h1>Infix Postfix Machine</h1>
      <Navigation />
    </div>
  );
}

export default Header;
